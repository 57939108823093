import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react'
import { useParams } from 'react-router';
import Header from '../common/Header/Header';

const ProjectDetails = () => {
    const { id } = useParams();
    const [project, setProject] = useState({});

    useEffect(() => {
        fetch("https://doublespace-server.onrender.com/api/projects")
            .then(res => res.json())
            .then(data => handleProjectDetails(data.projects))
    }, []);

    const handleProjectDetails = (data) => {
        const findProject = data.find(p => p._id == id)
        setProject(findProject);

    }

    return (
        <>
            <Header pageName={"projectDetails"} />
            <div className='bg-white lg:px-24 md:px-12 px-8 py-20'>
                <div className='grid lg:grid-cols-5 grid-cols-1 gap-8'>
                    <div className='lg:col-span-3'>
                        <img className='object-cover h-96 w-full' src={project.coverphoto} alt="" />
                    </div>

                    <div className='lg:col-span-2'>
                        <h1 className='text-4xl font-extrabold uppercase'> {project.title}</h1>
                        <p className='text-gray-600 py-2 lg:text-left text-justify'>{project.description}</p>
                        <div>
                            <p className='text-md font-bold py-1 lg:text-left text-justify'>ARCHITECT:<span className='text-md  font-normal py-1 ml-2 text-justify'>Partha Balaji</span></p>
                            <p className='text-md font-bold py-1 lg:text-left text-justify'>CLIENT:<span className='text-md  font-normal py-1 ml-2 text-justify'>{project.client}</span></p>
                            <p className='text-md font-bold py-1 lg:text-left text-justify'>LOCATION:<span className='text-md  font-normal py-1 ml-2 text-justify'>{project.address}</span></p>
                            <p className='text-md font-bold py-1 lg:text-left text-justify'>Total Area:<span className='text-md  font-normal py-1 ml-2 text-justify'>{project.flatSize}</span></p>
                            <p className='text-md font-bold py-1 lg:text-left text-justify'>CATEGORY:<span className='text-md  font-normal py-1 ml-2 text-justify'>Structure</span></p>
                        </div>
                    </div>
                </div>
                <div className='grid lg:grid-cols-2 grid-cols-1 gap-8 mt-6'>

                    {
                        project?.imgsrc?.map((p, i) => <div className='my-2'>
                            <img className='object-cover h-96 w-full' src={p} alt="" />
                        </div>)
                    }
                </div>
            </div>
        </>
    );
};

export default ProjectDetails;